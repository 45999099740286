import Blockly from 'blockly';
import { codeRunnerText, loopTrapVariableName } from '@adsk/informed-design-code-runner';

export const blocklyKinds = {
  BLOCK: 'block',
  CATEGORY: 'category',
  CATEGORY_TOOLBOX: 'categoryToolbox',
};

// Unicode U+26A0
export const invalidInputSymbol = '⚠';

export const BLOCKLY_EVENTS_TO_UPDATE = [
  Blockly.Events.BLOCK_CHANGE,
  Blockly.Events.BLOCK_CREATE,
  Blockly.Events.BLOCK_DELETE,
  Blockly.Events.BLOCK_MOVE,
  Blockly.Events.COMMENT_CHANGE,
  Blockly.Events.COMMENT_CREATE,
  Blockly.Events.COMMENT_MOVE,
  Blockly.Events.COMMENT_DELETE,
];

export const blocklyInputsDropdown = 'dropdown';

export const CONTEXT_MENU_ENABLED = 'enabled';
export const CONTEXT_MENU_DISABLED = 'disabled';
export const CONTEXT_MENU_HIDDEN = 'hidden';
export const CONTEXT_MENU_DUPLICATE_CONNECTED = 'duplicateConnected';
export const CONTEXT_MENU_EXPORT = 'exportBlocks';
export const CONTEXT_MENU_IMPORT = 'importBlocks';

export const INFINITE_LOOP_TRAP_CODE = `if(--${loopTrapVariableName} == 0) throw "${codeRunnerText.codeRunnerInfiniteLoopError}";\n`;
export const DATA_STORE_DEBOUNCE_TIME = 100;

export const muiDarkPaletteMode = 'dark';
