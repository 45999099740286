import { logError } from 'mid-utils';
import { webview2BrowserApiService } from '../../services';
import { DialogStatus } from '../../interfaces/fileSystem';
import {
  ExportCodeBlocksOptions,
  ExportCodeBlocksResult,
  ExportCodeBlocksStatus,
} from '../../interfaces/inventorCodeBlocks';
import {
  ImportCodeBlocksOptions,
  ImportCodeBlocksResult,
  ImportCodeBlocksStatus,
} from '../../interfaces/inventorCodeBlocks';
import text from '../../mid-addin-lib.text.json';

// They are currently only used in Inventor, since Fusion is only supporting static content at this point
// We can move this into sharedInterfaces once Fusion supports configurable content.

export const exportCodeBlocks = async (
  codeBlocks: string,
  options: ExportCodeBlocksOptions,
): Promise<ExportCodeBlocksResult> => {
  if (!webview2BrowserApiService.saveToFileUsingDialog) {
    const errorMessage = 'saveToFileUsingDialog is not implemented in host API';
    logError(errorMessage);
    return {
      status: ExportCodeBlocksStatus.error,
      message: errorMessage,
    };
  }

  const skipDialog = options.skipDialog ?? false;
  const filter = JSON.stringify([{ name: text.codeBlocksFilter.name, expression: text.codeBlocksFilter.expression }]);
  const fileName = options.fileName;
  const fileLocation = options.fileLocation;
  const title = text.exportCodeBlocksTitle;

  const result = await webview2BrowserApiService.saveToFileUsingDialog(
    codeBlocks,
    fileName,
    fileLocation,
    filter,
    title,
    skipDialog,
  );
  if (result.value === null) {
    return {
      status: ExportCodeBlocksStatus.error,
      message: result.errorMessage ?? '',
    };
  }

  switch (result.value?.status) {
    case DialogStatus.success:
      return {
        status: ExportCodeBlocksStatus.success,
      };
    case DialogStatus.cancel:
      return {
        status: ExportCodeBlocksStatus.cancel,
      };
    default:
      return {
        status: ExportCodeBlocksStatus.error,
        message: result.errorMessage ?? '',
      };
  }
};

export const importCodeBlocks = async (options: ImportCodeBlocksOptions): Promise<ImportCodeBlocksResult> => {
  if (!webview2BrowserApiService.readFromFileUsingDialog) {
    const errorMessage = 'readFromFileUsingDialog is not implemented in host API';
    logError(errorMessage);
    return {
      status: ImportCodeBlocksStatus.error,
      message: errorMessage,
    };
  }

  const skipDialog = options.skipDialog ?? false;
  const filter = JSON.stringify([{ name: text.codeBlocksFilter.name, expression: text.codeBlocksFilter.expression }]);
  const fileName = options.fileName ?? '';
  const fileLocation = options.fileLocation;
  const title = text.importCoeBlocksTitle;

  const result = await webview2BrowserApiService.readFromFileUsingDialog(fileName, fileLocation, filter, title, skipDialog);
  if (result.value === null) {
    return {
      status: ImportCodeBlocksStatus.error,
      message: result.errorMessage ?? '',
    };
  }

  switch (result.value?.status) {
    case DialogStatus.success:
      return {
        status: ImportCodeBlocksStatus.success,
        codeBlocks: result.value?.content,
      };
    case DialogStatus.cancel:
      return {
        status: ImportCodeBlocksStatus.cancel,
      };
    default:
      return {
        status: ImportCodeBlocksStatus.error,
        message: result.errorMessage ?? '',
      };
  }
};
