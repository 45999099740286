import { ProductDefinition } from 'mid-addin-lib';
import { ProductDefinitionEngine } from 'mid-types';
import React from 'react';

export interface CustomizerDataStore {
  initialProductDefinitionData: ProductDefinition;
  authoringAppEngine: ProductDefinitionEngine;
}

export const CustomizerDataContext = React.createContext({} as CustomizerDataStore);
