import { styled } from '@mui/material/styles';

export const PublishingHeaderContainer = styled('div')`
  height: ${({ theme }) => `${theme.var.publishHeaderHeight}px`};
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px ${theme.var.paddingBase * 4}px`};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PublishErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.var.marginBase}px`};
  gap: ${({ theme }) => `${theme.var.gapBase}px`};
`;
