import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { ProductDefinition } from 'mid-addin-lib';
import { LeftButton, useTableLoadingState } from '@mid-react-common/common';
import React, { useMemo } from 'react';
import { productDefinitionSelectionTestIds } from 'tests/helpers/dataTestIds';
import text from '../../inventor.text.json';
import { ProductDefinitionSelectionTable, ProductDefinitionTableHeader } from './ProductDefinitionTable.styles';
import { getColumns } from './ProductDefinitionTable.utils';
import useProductDefinitionTable from './useProductDefinitionTable';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface ProductDefinitionTableProps {
  productDefinitions: ProductDefinition[] | null;
  isFetching: boolean;
  handleEditProductDefinition: (productDefinition: ProductDefinition) => void;
  handleDeleteProductDefinitions: (productDefinitionIds: string[]) => Promise<void>;
  handleDuplicateProductDefinition: (productDefinition: ProductDefinition) => Promise<void>;
  handleNewProductDefinition: () => void;
}

const ProductDefinitionTable: React.FC<ProductDefinitionTableProps> = ({
  productDefinitions,
  isFetching,
  handleNewProductDefinition,
  handleEditProductDefinition,
  handleDeleteProductDefinitions,
  handleDuplicateProductDefinition,
}): JSX.Element => {
  const theme = useTheme();
  const { enableStaticContent } = useFlags();

  const {
    tableData,
    numberOfProductDefinitionsSelected,
    duplicateLoading,
    handleNewProductDefinitionClickWithConfirmation,
    handleEditProductDefinitionButtonClick,
    handleDuplicateProductDefinitionButtonClick,
    handleDeleteProductDefinitionsButtonClick,
    onProductDefinitionSelection,
    renderEmptyState,
  } = useProductDefinitionTable({
    productDefinitions,
    handleNewProductDefinition,
    handleEditProductDefinition,
    handleDeleteProductDefinitions,
    handleDuplicateProductDefinition,
  });
  const columns = useMemo(() => getColumns(enableStaticContent), [enableStaticContent]);

  return (
    <>
      <ProductDefinitionTableHeader>
        <LeftButton
          onClick={handleNewProductDefinitionClickWithConfirmation}
          size="small"
          variant="contained"
          startIcon={<AddCircleOutlinedIcon />}
          data-testid={productDefinitionSelectionTestIds.buttonNewProductDefinition}
        >
          {text.buttonNewProductDefinition}
        </LeftButton>
        <ButtonGroup variant="outlined">
          <Button
            size="small"
            disabled={numberOfProductDefinitionsSelected !== 1}
            onClick={handleEditProductDefinitionButtonClick}
            data-testid={productDefinitionSelectionTestIds.buttonEditProductDefinition}
          >
            <Tooltip title={text.productDefinitionTableEdit} placement="top" arrow>
              <EditIcon />
            </Tooltip>
          </Button>
          <Button
            size="small"
            disabled={numberOfProductDefinitionsSelected !== 1}
            onClick={handleDuplicateProductDefinitionButtonClick}
            data-testid={productDefinitionSelectionTestIds.buttonDuplicateProductDefinition}
          >
            {duplicateLoading ? (
              <CircularProgress size={theme.var.smallButtonSpinnerSize} />
            ) : (
              <Tooltip title={text.productDefinitionTableDuplicate} placement="top" arrow>
                <FileCopyIcon />
              </Tooltip>
            )}
          </Button>
          <Button
            size="small"
            disabled={numberOfProductDefinitionsSelected === 0}
            onClick={handleDeleteProductDefinitionsButtonClick}
            data-testid={productDefinitionSelectionTestIds.buttonDeleteProductDefinition}
          >
            <Tooltip title={text.productDefinitionTableDelete} placement="top" arrow>
              <DeleteIcon />
            </Tooltip>
          </Button>
        </ButtonGroup>
      </ProductDefinitionTableHeader>
      <ProductDefinitionSelectionTable
        density="compact"
        checkboxSelection
        hideFooterPagination
        columns={columns}
        rows={tableData}
        onSelectionModelChange={onProductDefinitionSelection}
        getRowId={(row) => row.id}
        loading={isFetching}
        components={{
          NoRowsOverlay: renderEmptyState,
          LoadingOverlay: useTableLoadingState,
        }}
      />
    </>
  );
};

export default ProductDefinitionTable;
