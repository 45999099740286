import { SvgIconComponent } from '@mui/icons-material';
import ContrastIcon from '@mui/icons-material/Contrast';
import text from 'inventor.text.json';
import React, { useContext, useEffect, useState } from 'react';
import InputsTab from '../../components/Tabs/Inputs/InputsTab';
import OutputsTab from '../../components/Tabs/Outputs/OutputsTab';
import RulesTab from '../../components/Tabs/Rules/RulesTab';
import SourceContentTab from '../../components/Tabs/SourceContent/SourceContentTab';
import TabProgressContext from '../../context/TabProgressStore/TabProgress.context';
import { TABS } from '../../context/TabProgressStore/tabProgressStore';
import { TabProgress } from '../../types';
import Box from '@mui/material/Box';
import { TabPanelWrapper, TabsWrapper } from './ProductDefinitionConfigurationScreen.styles';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

interface TabProgressIconsMap {
  [TabProgress.COMPLETED]: undefined;
  [TabProgress.PARTIALLY_COMPLETED]: SvgIconComponent;
  [TabProgress.EMPTY]: SvgIconComponent;
}

export const ProductDefinitionConfigurationScreen: React.FC = (): JSX.Element => {
  const { tabProgress, activeTab, changeTab } = useContext(TabProgressContext);
  const sourceContentTabProgress = tabProgress[TABS.SOURCE_CONTENT];
  const inputsTabProgress = tabProgress[TABS.INPUTS];
  const rulesTabProgress = tabProgress[TABS.RULES];
  const outputsTabProgress = tabProgress[TABS.OUTPUTS];
  const [disableInputsTab, setDisableInputsTab] = useState<boolean>(true);
  const [disableOutputsTab, setDisableOutputsTab] = useState<boolean>(true);
  const [disableRulesTab, setDisableRulesTab] = useState<boolean>(true);

  useEffect(() => {
    const sourceContentFormIsNotComplete = sourceContentTabProgress !== TabProgress.COMPLETED;
    const inputsSelectionIsNotComplete = inputsTabProgress !== TabProgress.COMPLETED;

    setDisableInputsTab(sourceContentFormIsNotComplete);
    setDisableOutputsTab(sourceContentFormIsNotComplete || inputsSelectionIsNotComplete);
    setDisableRulesTab(sourceContentFormIsNotComplete || inputsSelectionIsNotComplete);
  }, [sourceContentTabProgress, inputsTabProgress]);

  const tabProgressIconsMap: TabProgressIconsMap = {
    [TabProgress.COMPLETED]: undefined,
    [TabProgress.PARTIALLY_COMPLETED]: ContrastIcon,
    [TabProgress.EMPTY]: ContrastIcon,
  };

  const SourceContentTabIcon = tabProgressIconsMap[sourceContentTabProgress];
  const InputsTabIcon = tabProgressIconsMap[inputsTabProgress];
  const RulesTabIcon = tabProgressIconsMap[rulesTabProgress];
  const OutputTabIcon = tabProgressIconsMap[outputsTabProgress];

  const handleOnChangeTabClick = (_: React.SyntheticEvent<Element, Event>, value: TABS): void => {
    changeTab(value);
  };

  return (
    <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleOnChangeTabClick}>
          <TabsWrapper
            label={text.tabSourceContentLabel}
            data-testid={`tab-${TABS.SOURCE_CONTENT}`}
            value={TABS.SOURCE_CONTENT}
            icon={SourceContentTabIcon && <SourceContentTabIcon />}
            iconPosition="end"
          />
          <TabsWrapper
            label={text.tabParametersLabel}
            data-testid={`tab-${TABS.INPUTS}`}
            value={TABS.INPUTS}
            disabled={disableInputsTab}
            icon={InputsTabIcon && <InputsTabIcon />}
            iconPosition="end"
          />
          <TabsWrapper
            label={text.tabRulesLabel}
            data-testid={`tab-${TABS.RULES}`}
            value={TABS.RULES}
            disabled={disableRulesTab}
            icon={RulesTabIcon && <RulesTabIcon />}
            iconPosition="end"
          />
          <TabsWrapper
            label={text.tabOutputsLabel}
            data-testid={`tab-${TABS.OUTPUTS}`}
            value={TABS.OUTPUTS}
            disabled={disableOutputsTab}
            icon={OutputTabIcon && <OutputTabIcon />}
            iconPosition="end"
          />
        </TabList>
      </Box>
      <TabPanelWrapper value={TABS.SOURCE_CONTENT}>
        <SourceContentTab />
      </TabPanelWrapper>
      <TabPanelWrapper value={TABS.INPUTS}>
        <InputsTab />
      </TabPanelWrapper>
      <TabPanelWrapper value={TABS.RULES}>
        <RulesTab />
      </TabPanelWrapper>
      <TabPanelWrapper value={TABS.OUTPUTS}>
        <OutputsTab />
      </TabPanelWrapper>
    </TabContext>
  );
};
