export const fusionCommands = {
  LOAD_PRODUCT_DEFINITIONS: 'LoadProductDefinitions',
  SAVE_PRODUCT_DEFINITIONS: 'SaveProductDefinitions',
  MODEL_HAS_SWITCHED: 'ModelHasSwitched',
  GET_ACTIVE_DOCUMENT_INFO: 'GetActiveDocumentInfo',
  GET_THUMBNAIL_IMAGE: 'GetThumbnailImage',
  GET_PART_OR_ASSEMBLY_PROPERTIES: 'GetPartOrAssemblyProperties',
  GET_ENVIRONMENT: 'GetEnvironment',
  GET_DC_API_URL: 'GetDcApiUrl',
  GET_MID_WEBAPP_URL: 'GetMIDWebAppUrl',
  FILE_TO_BASE64_STRING: 'FileToBase64String',
  COMPRESS_FOLDER: 'CompressFolder',
  GENERATE_OUTPUTS: 'GenerateOutputs',
  GET_ASSEMBLY_VERSION: 'GetAssemblyVersion',
  OPEN_EXTERNAL_URL: 'OpenExternalUrl',
  DELETE_FILE: 'DeleteFile',
  CHECK_AND_GENERATE_THUMBNAIL_IN_BASE64: 'CheckAndGenerateThumbnailInBase64',
  GET_APPLICATION_VERSION_NUMBER: 'GetApplicationVersionNumber',
  SAVE_TO_FILE: 'SaveToFile',
  GET_REVIT_CLASSIFICATION: 'GetRevitClassification',
  OPEN_PRODUCT_DEFINITION_DOCUMENT: 'OpenProductDefinitionDocument',
  IS_DOCUMENT_OPEN_IN_THE_EDITOR: 'IsDocumentOpenInTheEditor',
  RUN_PUBLISH_CHECKS: 'RunPublishChecks',
  GET_CACHED_OAUTH_TOKENS: 'GetCachedOAuthTokens',
  SET_CACHED_OAUTH_TOKENS: 'SetCachedOAuthTokens',
} as const;

export type FusionCommand = (typeof fusionCommands)[keyof typeof fusionCommands];

export type CachedOAuthTokens = {
  accessToken: string;
  refreshToken: string;
};
