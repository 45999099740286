import { GridFilterModel, GridSelectionModel } from '@mui/x-data-grid';
import text from 'inventor.text.json';
import { InventorParameter } from 'mid-addin-lib';
import { MIDEmptyState, TableEmptyStateWrapper } from '@mid-react-common/common';
import { useEffect, useState } from 'react';
import { filterParameters } from './utils';
import { InputHeaderCurrentFilter } from './useInputsHeader';
import { productDefinitionActions } from 'context/DataStore/productDefinitionStore';
import { formRulesKey } from 'components/Rules/FormCodeblocks/FormCodeblocks.constants';

export interface UseInputsTableState {
  tableData: InventorParameter[];
  filteredData: GridFilterModel;
  onInputSelection: (selectedInputs: GridSelectionModel) => void;
  renderEmptyState: () => JSX.Element;
}

export interface UseInputsTableArgs {
  initialTableData: InventorParameter[] | null;
  currentFilter: InputHeaderCurrentFilter;
  setSelectedInputIds: (id: GridSelectionModel) => void;
  isProductConfigurable: boolean;
}

export const useInputsTable = ({
  initialTableData,
  currentFilter,
  setSelectedInputIds,
  isProductConfigurable,
}: UseInputsTableArgs): UseInputsTableState => {
  const [tableData, setTableData] = useState<InventorParameter[]>(initialTableData || []);
  const [filteredData, setFilteredData] = useState<GridFilterModel>({ items: [] });

  const onInputSelection = (selectedInputs: GridSelectionModel) => {
    if (!isProductConfigurable && selectedInputs.length === 0) {
      productDefinitionActions.removeRule(formRulesKey);
      productDefinitionActions.setFormCodeBlocksWorkspace(undefined);
    }
    setSelectedInputIds(selectedInputs);
  };

  useEffect(() => {
    if (initialTableData) {
      setTableData(initialTableData);
    }
  }, [initialTableData]);

  useEffect(() => {
    const filteredData = filterParameters(currentFilter.parameters);
    setFilteredData(filteredData);
  }, [currentFilter]);

  const renderEmptyState = (): JSX.Element => (
    <TableEmptyStateWrapper>
      <MIDEmptyState title={text.EmptyStateNoParameters} />
    </TableEmptyStateWrapper>
  );

  return {
    tableData,
    filteredData,
    onInputSelection,
    renderEmptyState,
  };
};
