import { styled } from '@mui/material/styles';
import { DividerWrapper } from '@mid-react-common/common';

export const SourceContentForm = styled('form')`
  display: flex;
  gap: ${({ theme }) => `${theme.var.marginBase}px`};
  justify-content: flex-start;
  flex-direction: column;
`;

export const SourceContentPreviewImage = styled('img')`
  width: ${({ theme }) => `${theme.var.wideInputControl}px`};
  height: auto;
`;

export const SourceContentPreviewWrapper = styled('div')`
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
  overflow: auto;
  max-height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`});
`;

export const GridWrapper = styled('div')`
  display: grid;
  gap: ${({ theme }) => `${theme.var.marginBase * 2}px`};
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: ${({ theme }) => theme.screenSizes.tabletLandscape}) {
    grid-template-columns: 1fr;
  }
`;

export const DividerSection = styled(DividerWrapper)`
  @media only screen and (min-width: ${({ theme }) => theme.screenSizes.tabletLandscape}) {
    display: none;
  }
`;

export const ThumbnailContainer = styled('div')`
  width: ${({ theme }) => `${theme.var.wideInputControl}px`};
`;

export const SpinnerWrapper = styled('div')`
  width: fit-content;
  margin: auto;
`;

export const DrawingImageWrapper = styled('img')`
  height: ${({ theme }) => `${theme.var.drawingsSize}px`};
`;

export const ProductDefinitionContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => `${theme.var.gapBase}px`};
`;
