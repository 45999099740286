import { midTheme, parameterSymbolSvg } from '@mid-react-common/common';
import Blockly, { Input } from 'blockly';
import { ContextMenuOption } from 'blockly/core/contextmenu_registry';
import text from 'inventor.text.json';
import {
  ADD_TAB_BLOCK_EXTENSION,
  ADD_TAB_BLOCK_MUTATOR,
  blocklyFormName,
  blocklyLabel,
  blocklyStatementInput,
  CONTROL_BLOCK_EXTENSION,
  CONTROL_BLOCK_MUTATOR,
  controlBlock,
  controlBlockColor,
  formContainerBlock,
  formContainerBlockColor,
  groupBlock,
  groupBlockColor,
  groupBlockInnerBlocksField,
  groupBlockName,
  groupBlockOpenByDefaultField,
  workspaceHelpLink,
} from './FormCodeblocks.constants';
import { javascriptGenerator } from 'blockly/javascript';
import { Abstract } from 'blockly/core/events/events_abstract';
import { blocklyInputsDropdown } from '../constants';
import {
  getTabInputContainers,
  getStatementInputName,
  getTabNameFieldName,
  getUniqueIdFromTabInputContainerName,
} from './FormCodeblocks.utils';

/*
 * Control block
 */

Blockly.Blocks[controlBlock] = {
  init() {
    this.appendDummyInput().appendField(
      new Blockly.FieldImage(parameterSymbolSvg, midTheme.var.blocklyIconSize, midTheme.var.blocklyIconSize),
    );
    this.appendDummyInput(blocklyInputsDropdown).appendField(blocklyInputsDropdown, blocklyInputsDropdown);
    this.appendDummyInput().appendField(text.blocklyBlockLabel);
    this.appendDummyInput(blocklyLabel).appendField(new Blockly.FieldTextInput(), blocklyLabel);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(controlBlockColor);
    this.setHelpUrl(workspaceHelpLink);
    this.setTooltip(text.blocklyControlBlockTooltip);
    this.jsonInit({
      extensions: [CONTROL_BLOCK_EXTENSION],
      mutator: CONTROL_BLOCK_MUTATOR,
    });
  },
  // Override the context menu to remove the "External Inputs" option
  customContextMenu(options: ContextMenuOption[]) {
    const optionIndex = options.findIndex((option) => option.text === Blockly.Msg.EXTERNAL_INPUTS);
    if (optionIndex !== -1) {
      options.splice(optionIndex, 1);
    }

    // Remove the "Enable Block" option when block is orphaned
    if (this.getRootBlock().type !== formContainerBlock) {
      const optionIndex = options.findIndex((option) => option.text === Blockly.Msg.ENABLE_BLOCK);
      if (optionIndex !== -1) {
        options.splice(optionIndex, 1);
      }
    }
  },
};

javascriptGenerator[controlBlock] = function (this: Blockly.Block) {
  if (this.getRootBlock().type !== formContainerBlock) {
    return;
  }
  const dropdownParameter = this.getField(blocklyInputsDropdown)?.getValue();
  const label = this.getField(blocklyLabel)?.getValue();

  return `{ "name": "${dropdownParameter}", "label": "${label}" },\n`;
};

/*
 * Form Container block
 */

Blockly.Blocks[formContainerBlock] = {
  init() {
    this.appendDummyInput(formContainerBlock)
      .appendField(text.blocklyForm)
      .appendField(new Blockly.FieldTextInput(text.blocklyBlockFormName), blocklyFormName);
    this.appendEndRowInput();
    this.appendStatementInput(blocklyStatementInput);
    this.setColour(formContainerBlockColor);
    this.setHelpUrl(workspaceHelpLink);
    this.setDeletable(false);
    this.jsonInit({
      extensions: [ADD_TAB_BLOCK_EXTENSION],
      mutator: [ADD_TAB_BLOCK_MUTATOR],
    });
  },
  // Override the context menu to remove the "Disable Block" option
  customContextMenu(options: ContextMenuOption[]) {
    const optionIndex = options.findIndex((option) => option.text === Blockly.Msg.DISABLE_BLOCK);
    if (optionIndex !== -1) {
      options.splice(optionIndex, 1);
    }
  },
};

javascriptGenerator[formContainerBlock] = function (this: Blockly.Block) {
  const formName = this.getField(blocklyFormName)?.getValue();
  // Check whether there are some tabs or not
  const tabContainers = getTabInputContainers(this.inputList);
  if (tabContainers.length > 0) {
    // Loop through each of tabs
    const statements = tabContainers.reduce((prevStatements: any[], currentTabContainer: Input) => {
      const tabUniqueId = getUniqueIdFromTabInputContainerName(currentTabContainer.name);
      const tabName = this.getFieldValue(getTabNameFieldName(tabUniqueId));
      const tabStatementInputName = getStatementInputName(tabUniqueId);
      const currentStatement = javascriptGenerator.statementToCode(this, tabStatementInputName);
      const currentStatementWithoutEndingComma = currentStatement.replace(/,\n$/, '\n');
      prevStatements.push(
        `{\n "tabName": "${tabName}",\n "tabId": "${tabUniqueId}", \n"inputs": [\n${currentStatementWithoutEndingComma}]\n}`,
      );
      return prevStatements;
    }, []);
    const withoutEndingComma = statements.join(',').replace(/,$/, '\n');
    return `{\n "formName": "${formName}",\n "tabs": [\n${withoutEndingComma}]\n}`;
  }
  const statements = javascriptGenerator.statementToCode(this, blocklyStatementInput);
  const withoutEndingComma = statements.replace(/,\n$/, '\n');
  return `{\n "formName": "${formName}",\n "inputs": [\n${withoutEndingComma}]\n}`;
};

/*
 * Group block
 */

Blockly.Blocks[groupBlock] = {
  init() {
    this.appendDummyInput()
      .appendField(text.blocklyGroup)
      .appendField(new Blockly.FieldTextInput(text.blocklyDefaultGroupName), groupBlockName)
      .appendField(text.blocklyGroupOpenByDefault)
      .appendField(new Blockly.FieldCheckbox('TRUE'), groupBlockOpenByDefaultField);
    this.appendStatementInput(groupBlockInnerBlocksField);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(groupBlockColor);
    this.setTooltip(text.blocklyGroupBlockTooltip);
    this.setHelpUrl(workspaceHelpLink);
  },
  onchange(e: Abstract) {
    if (this.workspace.isDragging()) {
      return;
    }
    if (e.type !== Blockly.Events.BLOCK_MOVE) {
      return;
    }
    // If the blocks added to groupBlockInnerBlocksField is not a controlBlock, remove it
    let innerBlocks = this.getInput(groupBlockInnerBlocksField).connection?.targetBlock();
    if (innerBlocks && (innerBlocks.outputConnection || innerBlocks.previousConnection)) {
      do {
        if (innerBlocks.type !== controlBlock) {
          innerBlocks.unplug(true);
        }
        innerBlocks = innerBlocks.getNextBlock();
      } while (innerBlocks);
    }
  },
  // Override the context menu to remove the "Disable Block" option
  customContextMenu(options: ContextMenuOption[]) {
    // Remove the "Enable Block" option when block is orphaned
    if (this.getRootBlock().type !== formContainerBlock) {
      const optionIndex = options.findIndex((option) => option.text === Blockly.Msg.ENABLE_BLOCK);
      if (optionIndex !== -1) {
        options.splice(optionIndex, 1);
      }
    }
  },
};

javascriptGenerator[groupBlock] = function (this: Blockly.Block) {
  const innerBlocksCode = javascriptGenerator.statementToCode(this, groupBlockInnerBlocksField);

  const withoutEndingComma = innerBlocksCode.replace(/,\n$/, '\n');
  const groupName = this.getFieldValue(groupBlockName);
  const openByDefault = this.getFieldValue(groupBlockOpenByDefaultField);

  return `{ "groupName": "${groupName}", "openByDefault": ${
    openByDefault === 'TRUE'
  }, "inputs": [${withoutEndingComma}] },\n`;
};
