import { ProductDefinition } from 'mid-addin-lib';

export const productDefinitionWithoutData: ProductDefinition = {
  id: '',
  name: '',
  releaseName: '',
  lastUpdated: 0,
  account: { id: '', name: '' },
  project: { id: '', name: '' },
  folder: { id: '', name: '', parentPath: [] },
  topLevelFolder: '',
  inventorProject: '',
  assembly: '',
  thumbnail: '',
  drawingThumbnails: [],
  inputs: [],
  parametersDefaults: [],
  rules: [],
  outputs: [],
  notes: undefined,
  codeBlocksWorkspace: undefined,
  formCodeBlocksWorkspace: undefined,
  latestContentId: undefined,
};
