export const CONTROL_BLOCK_EXTENSION = 'CONTROL_BLOCK_EXTENSION';
export const CONTROL_BLOCK_MUTATOR = 'CONTROL_BLOCK_MUTATOR';
export const FORM_CONTAINER_BLOCK_MUTATOR = 'FORM_CONTAINER_BLOCK_MUTATOR';
export const ADD_TAB_BLOCK_EXTENSION = 'ADD_TAB_BLOCK_EXTENSION';
export const ADD_TAB_BLOCK_MUTATOR = 'ADD_TAB_BLOCK_MUTATOR';

// FormCodeblocks Input and Field constants
export const blocklyLabel = 'label';
export const blocklyStatementInput = 'statement_input';
export const blocklyFormName = 'form_name';
export const controlBlock = 'control_block';
export const formContainerBlock = 'form_container_block';
export const groupBlock = 'group_block';
export const groupBlockName = 'group_block_name';
export const groupBlockOpenByDefaultField = 'open_by_default';
export const groupBlockInnerBlocksField = 'inner_blocks';

export const controlBlockColor = '#A55B7F';
export const formContainerBlockColor = '#A57A5B';
export const groupBlockColor = '#A57A5B';

export const formRulesKey = 'formRules';

export const workspaceHelpLink = 'https://developers.google.com/blockly/guides/get-started/workspace-anatomy';

// FORM TAB INPUTS
export const namePrefixUniqueIdSeparator = '-';
export const tabInputContainerIdPrefix = `tabInputContainer`;
export const tabNumberFieldPrefix = `tabNumberField`;
export const tabNameFieldPrefix = `tabNameField`;
export const tabStatementInputNamePrefix = 'tabStatementInput';
export const plusSignFieldName = 'plus-sign';

export const plusImageBase64 =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC' +
  '9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPSJNMT' +
  'ggMTBoLTR2LTRjMC0xLjEwNC0uODk2LTItMi0ycy0yIC44OTYtMiAybC4wNzEgNGgtNC4wNz' +
  'FjLTEuMTA0IDAtMiAuODk2LTIgMnMuODk2IDIgMiAybDQuMDcxLS4wNzEtLjA3MSA0LjA3MW' +
  'MwIDEuMTA0Ljg5NiAyIDIgMnMyLS44OTYgMi0ydi00LjA3MWw0IC4wNzFjMS4xMDQgMCAyLS' +
  '44OTYgMi0ycy0uODk2LTItMi0yeiIgZmlsbD0id2hpdGUiIC8+PC9zdmc+Cg==';
export const plusImageAltText = 'Plus sign';

export const minusImageBase64 =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAw' +
  'MC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPS' +
  'JNMTggMTFoLTEyYy0xLjEwNCAwLTIgLjg5Ni0yIDJzLjg5NiAyIDIgMmgxMmMxLjEwNCAw' +
  'IDItLjg5NiAyLTJzLS44OTYtMi0yLTJ6IiBmaWxsPSJ3aGl0ZSIgLz48L3N2Zz4K';
export const minusImageAltText = 'Minus sign';
export const formTabControlImageSize = 15;

export const MAX_TABS_ALLOWED_NUMBER = 12;
