import {
  PrintMessageDialog,
  ProductCustomizationForm,
  productCustomizationTestIds,
  usePrintMessageDialog,
} from '@mid-react-common/addins';
import { StateSetter } from '@mid-react-common/common';
import { ErrorCode, getProductConfigurableStatus } from 'mid-utils';
import { ControlsWrapper, PreviewPlaceholder, ProductCustomizationFormWrapper } from '../Rules.styles';
import { CodeblocksWorkspaceType } from '../types';
import { FormWorkspaceControls } from './FormWorkspaceControls';
import { InputWorkspaceControls } from './InputWorkspaceControls';
import { useProductFormPreview } from './useProductFormPreview';
import { useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { MutableRefObject } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { StaticContentParameterAdoption } from './StaticContentParameterAdoption';

const { productCustomizationFormWrapper } = productCustomizationTestIds;

interface ProductFormPreviewProps {
  updateFormEnabled: boolean;
  setUpdateFormEnabled: StateSetter<boolean>;
  selectedWorkspace: CodeblocksWorkspaceType;
  setHighlightedBlockId: (highlightedBlockId: string | undefined) => void;
  areRulesLoaded: boolean;
  getCodeRef: MutableRefObject<(() => string) | undefined>;
}

export const ProductFormPreview: React.FC<ProductFormPreviewProps> = ({
  setUpdateFormEnabled,
  updateFormEnabled,
  selectedWorkspace,
  setHighlightedBlockId,
  areRulesLoaded,
  getCodeRef,
}): JSX.Element => {
  const { inputs, isConfigurable } = useProductDefinitionStore(
    useShallow((state) => ({ inputs: state.inputs, isConfigurable: state.isConfigurable })),
  );
  const { isMessageDialogOpen, dialogMessage, showMessageDialog, closeMessageDialog } = usePrintMessageDialog();
  const {
    handleUpdateForm,
    handleSetModelValues,
    handleResetToDefaults,
    handleGetModelValues,
    handleInputUpdate,
    handleTabChange,
    inputsError,
    currentFormRules,
    isFormLoading,
    tabValue,
  } = useProductFormPreview({
    showMessageDialog,
    setUpdateFormEnabled,
    setHighlightedBlockId,
    areRulesLoaded,
    getCodeRef,
  });

  // the error might come from both CodeRunner or
  // from the custom .error prop from the Product Definition Input or
  // some invalid value type in some input
  const hasInputsError = inputs.some((input) => input.error) || inputsError?.errorCode === ErrorCode.CodeRunnerError;

  const isProductConfigurable = getProductConfigurableStatus(isConfigurable);
  const isStaticContentWithNoInputs = !isProductConfigurable && inputs.length === 0;

  return (
    <PreviewPlaceholder className="mid-bg-shadow">
      {isStaticContentWithNoInputs ? (
        <StaticContentParameterAdoption />
      ) : (
        <ControlsWrapper>
          {(selectedWorkspace === CodeblocksWorkspaceType.INPUT || !isProductConfigurable) && (
            <InputWorkspaceControls
              handleUpdateForm={handleUpdateForm}
              handleSetModelValues={handleSetModelValues}
              handleResetToDefaults={handleResetToDefaults}
              handleGetModelValues={handleGetModelValues}
              updateFormEnabled={updateFormEnabled}
              hasInputsError={hasInputsError}
              isProductConfigurable={isProductConfigurable}
            />
          )}
          {selectedWorkspace === CodeblocksWorkspaceType.FORM && isProductConfigurable && (
            <FormWorkspaceControls handleUpdateForm={handleUpdateForm} />
          )}
        </ControlsWrapper>
      )}
      <ProductCustomizationFormWrapper
        data-testid={productCustomizationFormWrapper}
        setMinHeight={isStaticContentWithNoInputs}
      >
        <ProductCustomizationForm
          inputs={inputs}
          inputsError={inputsError}
          formLayoutRules={currentFormRules}
          isFormLoading={isFormLoading}
          isProductConfigurable={isProductConfigurable}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          handleInputUpdate={handleInputUpdate}
        />
      </ProductCustomizationFormWrapper>
      <PrintMessageDialog isMessageDialogOpen={isMessageDialogOpen} closeMessageDialog={closeMessageDialog} isAddin>
        {dialogMessage}
      </PrintMessageDialog>
    </PreviewPlaceholder>
  );
};
