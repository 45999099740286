import { AvailableInputFunctionValues } from '../types';
import { InputType } from '@adsk/offsite-dc-sdk';
import { DCUIInputTypes } from 'mid-types';

export enum customBlocklyExtensions {
  INPUT_DROPDOWN_EXTENSION = 'INPUT_DROPDOWN_EXTENSION',
}

export enum customBlocklyMutators {
  INPUT_DROPDOWN_MUTATOR = 'INPUT_DROPDOWN_MUTATOR',
}

export const currentRuleKey = 'currentRule';
// Blockly Input and Field constants
export const blocklyParameterIcon = 'parameter_icon';
export const blocklyFunctionsDropdown = 'functions_dropdown';
export const blocklyToolboxInputsCategory = 'DCD_INPUTS';
export const blocklyConnectingBlock = 'connecting_block';
export const blocklyCommentBlockName = 'comment';
export const blocklyCommentBlockPlaceholder = 'comment here'; // Block types
export const universalInputBlock = 'universal_input_block';
export const universalOutputBlock = 'universal_output_block';
export const commentBlock = 'comment_block';
export const BACKPACK_CHANGE = 'backpack_change';
export const valueKey: AvailableInputFunctionValues = 'value';
export const visibleKey: AvailableInputFunctionValues = 'visible';
export const readOnlyKey: AvailableInputFunctionValues = 'readOnly';
export const labelKey: AvailableInputFunctionValues = 'label';
export const trueLabelKey: AvailableInputFunctionValues = 'trueLabel';
export const falseLabelKey: AvailableInputFunctionValues = 'falseLabel';
export const minKey: AvailableInputFunctionValues = 'min';
export const maxKey: AvailableInputFunctionValues = 'max';
export const incrementKey: AvailableInputFunctionValues = 'increment';
export const valuesKey: AvailableInputFunctionValues = 'values';
export const messageKey: AvailableInputFunctionValues = 'message';
export const errorKey: AvailableInputFunctionValues = 'error';
export const applicableKey: AvailableInputFunctionValues = 'applicable';
// All available keys for the universal input/output blocks
export const availableFunctionValuesArray = [
  valueKey,
  visibleKey,
  readOnlyKey,
  labelKey,
  trueLabelKey,
  falseLabelKey,
  minKey,
  maxKey,
  incrementKey,
  valuesKey,
  applicableKey,
] as const;
const availableFunctionsMap = new Map<string, AvailableInputFunctionValues>(
  Object.values(availableFunctionValuesArray).map((v) => [v, v]),
);

// helper to check if key for function is available
export function isFunctionAvailable(value: string): value is AvailableInputFunctionValues {
  return availableFunctionsMap.get(value) !== undefined;
}

export const blocklyBlockOptionsByType: {
  [key in DCUIInputTypes]: [AvailableInputFunctionValues, AvailableInputFunctionValues][];
} = {
  [InputType.BOOLEAN]: [
    [valueKey, valueKey],
    [trueLabelKey, trueLabelKey],
    [falseLabelKey, falseLabelKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [applicableKey, applicableKey],
    [labelKey, labelKey],
    [messageKey, messageKey],
    [errorKey, errorKey],
  ],
  [InputType.NUMERIC]: [
    [valueKey, valueKey],
    [valuesKey, valuesKey],
    [minKey, minKey],
    [maxKey, maxKey],
    [incrementKey, incrementKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [applicableKey, applicableKey],
    [labelKey, labelKey],
    [messageKey, messageKey],
    [errorKey, errorKey],
  ],
  [InputType.TEXT]: [
    [valueKey, valueKey],
    [valuesKey, valuesKey],
    [visibleKey, visibleKey],
    [readOnlyKey, readOnlyKey],
    [applicableKey, applicableKey],
    [labelKey, labelKey],
    [messageKey, messageKey],
    [errorKey, errorKey],
  ],
};

export const blocklyBlockOptionsByTypeExcludingApplicable = Object.fromEntries(
  Object.entries(blocklyBlockOptionsByType).map(([type, values]) => [type, values.filter(([key]) => key !== applicableKey)]),
);

export const blockTypeByInputType: {
  [key in DCUIInputTypes]: string;
} = {
  [InputType.BOOLEAN]: 'Boolean',
  [InputType.NUMERIC]: 'Number',
  [InputType.TEXT]: 'String',
};
export const blockTypeByFunctionType: {
  [key: string]: string;
} = {
  [valueKey]: 'String',
  [visibleKey]: 'Boolean',
  [readOnlyKey]: 'Boolean',
  [labelKey]: 'String',
  [trueLabelKey]: 'String',
  [falseLabelKey]: 'String',
  [minKey]: 'Number',
  [maxKey]: 'Number',
  [incrementKey]: 'Number',
  [applicableKey]: 'Boolean',
  [valuesKey]: 'Array',
  [messageKey]: 'String',
  [errorKey]: 'Boolean',
};
