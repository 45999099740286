export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

const CHARACTER_LIMIT = 255;
const SPECIAL_CHARACTERS = /[\\/:*?"<>|]/;

export const isCharacterLimitExceeded = (name: string): boolean => {
  if (name.length > CHARACTER_LIMIT) {
    return true;
  }
  return false;
};

export const containsSpecialCharacters = (name: string): boolean => {
  if (SPECIAL_CHARACTERS.test(name)) {
    return true;
  }

  return false;
};
