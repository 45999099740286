import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Help from '@mui/icons-material/Help';
import testIds from 'inventor.testids';
import text from 'inventor.text.json';
import React from 'react';
import { OutputRepresentationsTable, QuestionTooltipIcon } from './OutputsTab.styles';
import { OutputTypes } from '@adsk/offsite-dc-sdk';

interface OutputRepresentationsProps {
  outputType: OutputTypes;
  // TODO: Remove disabled prop when output representations are implemented https://jira.autodesk.com/browse/TRADES-6805
  disabled?: boolean;
  representations: string[];
  selectedRepresentations: string[];
  handleRepresentationChange: (outputType: OutputTypes, representationName: string, checked: boolean) => void;
  showExcludeIntellectualProperty?: boolean;
  tooltipMessage?: string;
  isRequired?: boolean;
}

const OutputRepresentations: React.FC<OutputRepresentationsProps> = ({
  outputType,
  disabled,
  representations,
  selectedRepresentations,
  handleRepresentationChange,
  showExcludeIntellectualProperty,
  tooltipMessage,
  isRequired,
}) => {
  const handleOutputRepresentationChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleRepresentationChange(outputType, event.target.name, checked);
  };

  const representationControls = representations.map((representation, index) => {
    const isSelected = selectedRepresentations.includes(representation);
    return (
      <TableRow key={`${representation}-${index}`}>
        <TableCell>
          {representation}
          {tooltipMessage && (
            <QuestionTooltipIcon title={tooltipMessage} color="action">
              <Help />
            </QuestionTooltipIcon>
          )}
        </TableCell>
        <TableCell align="right">
          <FormControlLabel
            data-testid={`${testIds.outputStatusPrefix}-${representation}`}
            labelPlacement="start"
            label={isSelected ? text.outputAvailable : text.outputUnavailable}
            control={
              <Switch
                name={representation}
                disabled={disabled}
                checked={isSelected}
                onChange={handleOutputRepresentationChange}
              />
            }
          />
        </TableCell>
      </TableRow>
    );
  });

  return (
    <OutputRepresentationsTable size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            {text.outputRepresentation} {isRequired && <span>*</span>}
          </TableCell>
          <TableCell align="right">{text.outputStatus}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {representationControls}
        {showExcludeIntellectualProperty && (
          <TableRow>
            <TableCell colSpan={2}>
              <FormControlLabel disabled control={<Checkbox />} checked label={text.drawingSettingsTableExcludeIp} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </OutputRepresentationsTable>
  );
};

export default OutputRepresentations;
