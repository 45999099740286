import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { useProductDefinitionStore } from 'context/DataStore/productDefinitionStore';
import text from 'inventor.text.json';
import { useShallow } from 'zustand/react/shallow';
import testIds from '../../../inventor.testids';
import { blocklyWorkspaceTestIds } from '../../../tests/helpers/dataTestIds';
import {
  LightTooltip,
  RulesWorkspaceToggleButton,
  RulesWorkspaceToggleButtonContainer,
  StaticContentTooltipContainer,
  WorkspaceSelectorGroup,
} from '../Rules.styles';
import { CodeblocksWorkspaceType } from '../types';
import { getProductConfigurableStatus } from 'mid-utils';
import { staticVsConfigurableProductsUrl } from '@mid-react-common/common';

interface WorkspaceSelectorProps {
  selectedWorkspace: CodeblocksWorkspaceType;
  setSelectedWorkspace: (workspace: CodeblocksWorkspaceType) => void;
}

const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = ({ selectedWorkspace, setSelectedWorkspace }): JSX.Element => {
  const handleWorkspaceChange = (event: React.MouseEvent<HTMLElement>, newSelectedWorkspace: CodeblocksWorkspaceType) => {
    if (newSelectedWorkspace !== null) {
      setSelectedWorkspace(newSelectedWorkspace);
    }
  };

  const isConfigurable = useProductDefinitionStore(useShallow((state) => state.isConfigurable));

  const isProductConfigurable = getProductConfigurableStatus(isConfigurable);

  return (
    <WorkspaceSelectorGroup
      exclusive
      size="small"
      value={selectedWorkspace}
      onChange={handleWorkspaceChange}
      data-testid={testIds.workspaceSelectorTestId}
    >
      <ToggleButton color="primary" value={CodeblocksWorkspaceType.FORM} data-testid={testIds.workspaceSelectorForm}>
        {text.blocklyForm}
      </ToggleButton>
      {isProductConfigurable ? (
        <ToggleButton color="primary" value={CodeblocksWorkspaceType.INPUT} data-testid={testIds.workspaceSelectorInput}>
          {text.blocklyInput}
        </ToggleButton>
      ) : (
        <RulesWorkspaceToggleButton
          color="primary"
          value={CodeblocksWorkspaceType.INPUT}
          data-testid={testIds.workspaceSelectorInput}
          disabled
        >
          <LightTooltip
            title={
              <StaticContentTooltipContainer>
                <Typography variant="body2">{text.staticContent.turnOnConfigurable}</Typography>
                <Link href={staticVsConfigurableProductsUrl} target="_blank" rel="noopener noreferrer" underline="hover">
                  <Typography variant="body2">{text.publishValidation.staticVsConfigurableProductsLink}</Typography>
                </Link>
              </StaticContentTooltipContainer>
            }
          >
            <RulesWorkspaceToggleButtonContainer data-testid={blocklyWorkspaceTestIds.workspaceInputButtonText}>
              <span>{text.blocklyInput}</span>
              <InfoOutlinedIcon color="action" fontSize="small" />
            </RulesWorkspaceToggleButtonContainer>
          </LightTooltip>
        </RulesWorkspaceToggleButton>
      )}
    </WorkspaceSelectorGroup>
  );
};

export default WorkspaceSelector;
