import { createFullPath, getActiveDocumentInfo } from 'mid-addin-lib';
import { useLogAndShowNotification } from '@mid-react-common/common';
import { useEffect, useMemo } from 'react';
import { productDefinitionActions, useProductDefinitionStore } from '../../../context/DataStore/productDefinitionStore';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

interface UseSourceContentState {
  initialName: string;
}

const INVENTOR_DOCUMENT_INFO_KEY = 'inventorDocumentInfo';

export const useSourceContent = (): UseSourceContentState => {
  const { productDefinitionName, assembly } = useProductDefinitionStore(
    useShallow((state) => ({
      productDefinitionName: state.name,
      topLevelFolder: state.topLevelFolder,
      assembly: state.assembly,
      inventorProject: state.inventorProject,
      outputs: state.outputs,
      drawingThumbnails: state.drawingThumbnails,
    })),
  );

  /*
   * Assembly
   */
  const { data: inventorDocumentInfo, error: inventorDocumentInfoError } = useQuery({
    queryKey: [INVENTOR_DOCUMENT_INFO_KEY],
    queryFn: async () => {
      const documentInfo = await getActiveDocumentInfo();
      if (!productDefinitionName) {
        productDefinitionActions.setName(documentInfo.name.replace('.ipt', '').replace('.iam', ''));
      }

      return documentInfo;
    },
  });

  useLogAndShowNotification(inventorDocumentInfoError, inventorDocumentInfoError?.message || '');

  useEffect(() => {
    if (inventorDocumentInfo && !assembly) {
      productDefinitionActions.setSourceModel({
        assembly: createFullPath(inventorDocumentInfo.location, inventorDocumentInfo.name),
      });
    }
  }, [assembly, inventorDocumentInfo]);

  /*
   * Initialize Product Definition Name
   */
  const initialName = useMemo(
    () => (inventorDocumentInfo?.name ? inventorDocumentInfo.name.replace('.ipt', '').replace('.iam', '') : ''),
    [inventorDocumentInfo?.name],
  );

  return {
    initialName,
  };
};

export default useSourceContent;
