import { create } from 'zustand';
import { produce } from 'immer';
import {
  InputRule,
  InventorParameter,
  ProductDefinition,
  ProductDefinitionInputParameter,
  ProductDefinitionOutput,
  SerializedBlocklyWorkspaceState,
  toProductDefinitionInputParameter,
} from 'mid-addin-lib';
import { DrawingThumbnail, MetaInfo, MetaInfoPath } from 'mid-types';
import { OutputTypes } from '@adsk/offsite-dc-sdk';
import { productDefinitionWithoutData } from './initialProductDefinition';

interface CurrentProductDefinitionSourceModel {
  topLevelFolder?: string;
  inventorProject?: string;
  assembly?: string;
}

export const useProductDefinitionStore = create<ProductDefinition>(() => productDefinitionWithoutData);

export const productDefinitionActions = {
  setProductDefinition: (productDefinition: ProductDefinition): void =>
    useProductDefinitionStore.setState(productDefinition),
  setName: (name: string): void => useProductDefinitionStore.setState({ name }),
  setSourceModel: ({ topLevelFolder, inventorProject, assembly }: CurrentProductDefinitionSourceModel): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        if (topLevelFolder) {
          state.topLevelFolder = topLevelFolder;
        }
        if (inventorProject) {
          state.inventorProject = inventorProject;
        }
        if (assembly) {
          state.assembly = assembly;
        }
      }),
    ),
  setThumbnail: (thumbnail: string): void => useProductDefinitionStore.setState({ thumbnail }),
  setPublishLocation: (account: MetaInfo, project: MetaInfo, folder: MetaInfoPath): void =>
    useProductDefinitionStore.setState({ account, project, folder }),
  setRule: (rule: InputRule): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        const currentRules: InputRule[] = state.rules;
        const existingRuleIndex = currentRules.findIndex((existingRule) => existingRule.key === rule.key);

        // If rule exists, update it
        if (existingRuleIndex !== -1) {
          const updatedRules = [...state.rules];
          updatedRules[existingRuleIndex] = rule;
          state.rules = updatedRules;
          return;
        }

        // otherwise, add it
        state.rules = [...currentRules, rule];
      }),
    ),
  removeRule: (ruleKey: string): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        const currentRules: InputRule[] = state.rules;

        const updatedRules = currentRules.filter((existingRule) => existingRule.key !== ruleKey);

        state.rules = updatedRules;
      }),
    ),
  setParametersDefaultsByInventorParameters: (
    inventorParameters: InventorParameter[],
  ): ProductDefinition['parametersDefaults'] => {
    const parametersDefaults = inventorParameters.map((param) => ({
      name: param.name,
      value: toProductDefinitionInputParameter(param).value,
    }));
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.parametersDefaults = parametersDefaults;
      }),
    );
    return parametersDefaults;
  },
  setDrawingsThumbnails: (drawingThumbnails: DrawingThumbnail[]): void =>
    useProductDefinitionStore.setState({ drawingThumbnails }),
  setNotes: (notes: string): void => useProductDefinitionStore.setState({ notes }),
  replaceAllInputs: (newParameters: ProductDefinitionInputParameter[]): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.inputs = newParameters;
      }),
    ),
  updateParameter: (parameterToUpdate: ProductDefinitionInputParameter, updatedValue: { [key: string]: any }): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.inputs = state.inputs.map((param: ProductDefinitionInputParameter) =>
          param.name === parameterToUpdate.name ? { ...param, ...updatedValue } : param,
        );
      }),
    ),
  addOutputOptions: (outputType: OutputTypes, outputOptions?: ProductDefinitionOutput['options']): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        const existingOutput: ProductDefinitionOutput | undefined = state.outputs.find(
          (output) => output.type === outputType,
        );

        const updatedOutput: ProductDefinitionOutput = {
          // We spread the existingOutput, so we don't change
          // anything besides adding output options
          ...existingOutput,
          type: outputType,
          ...(outputOptions && {
            options: {
              ...existingOutput?.options,
              ...outputOptions,
            },
          }),
        };

        const otherOutputs = state.outputs.filter((output) => output.type !== outputType);
        state.outputs = [...otherOutputs, updatedOutput];
      }),
    ),
  updateAvailableOutputOptions: (availableOutputOptions: string[]): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.outputs = state.outputs.map((output: ProductDefinitionOutput) => ({
          ...output,
          options: {
            ...output.options,
            modelStates: output.options?.modelStates?.filter((modelState) => availableOutputOptions.includes(modelState)),
          },
        }));
      }),
    ),
  removeOutput: (outputType: OutputTypes): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.outputs = state.outputs.filter((output: ProductDefinitionOutput) => output.type !== outputType);
      }),
    ),
  addOutputs: (addedOutputs: ProductDefinitionOutput[]): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.outputs = [...state.outputs, ...addedOutputs];
      }),
    ),
  deleteDrawingOutput: (outputType: OutputTypes, drawingTemplatePath: string): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.outputs = state.outputs.filter(
          (output) => !(output.type === outputType && output?.options?.drawingTemplatePath === drawingTemplatePath),
        );
      }),
    ),
  deleteDrawingOutputs: (drawingTemplatePaths: string[]): void =>
    useProductDefinitionStore.setState(
      produce((state: ProductDefinition) => {
        state.outputs = state.outputs.filter(
          (output) =>
            !(output?.options?.drawingTemplatePath && drawingTemplatePaths.includes(output.options.drawingTemplatePath)),
        );
      }),
    ),
  resetProductDefinition: (productDefinition: ProductDefinition): void =>
    useProductDefinitionStore.setState(productDefinition),
  setInputCodeBlocksWorkspace: (codeBlocksWorkspace: SerializedBlocklyWorkspaceState | undefined): void =>
    useProductDefinitionStore.setState({ codeBlocksWorkspace }),

  setFormCodeBlocksWorkspace: (formCodeBlocksWorkspace: SerializedBlocklyWorkspaceState | undefined): void =>
    useProductDefinitionStore.setState({ formCodeBlocksWorkspace }),
  setConfigurable: (isConfigurable: boolean): void => useProductDefinitionStore.setState({ isConfigurable }),
};
