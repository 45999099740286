import { DividerWrapper } from '@mid-react-common/common';
import ProductDefinitionName from './ProductDefinitionName/ProductDefinitionName';
import Drawings from './Drawings/Drawings';
import PreviewThumbnail from './PreviewThumbnail/PreviewThumbnail';
import { DividerSection, GridWrapper, SourceContentPreviewWrapper } from './SourceContentTab.styles';
import useSourceContent from './useSourceContent';
import useThumbnail from './useThumbnail';
import { useContext } from 'react';
import CustomComponentsContext from 'context/Customizers/customizerComponents.context';

const SourceContentTab: React.FC = (): JSX.Element => {
  const { AssemblyComponent } = useContext(CustomComponentsContext);
  const { initialName } = useSourceContent();

  const { thumbnailInBase64, isThumbnailLoading, handleRefreshThumbnail } = useThumbnail();

  return (
    <SourceContentPreviewWrapper>
      <ProductDefinitionName initialName={initialName} />
      <DividerWrapper />
      <GridWrapper>
        {AssemblyComponent}
        <DividerSection />
        <PreviewThumbnail
          thumbnailInBase64={thumbnailInBase64}
          isThumbnailLoading={isThumbnailLoading}
          handleRefreshThumbnail={handleRefreshThumbnail}
        />
      </GridWrapper>
      <DividerWrapper />
      <Drawings />
    </SourceContentPreviewWrapper>
  );
};

export default SourceContentTab;
