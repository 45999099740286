import { ProductDefinitionsCrudUtils } from 'mid-addin-lib';
import text from 'inventor.text.json';
import Typography from '@mui/material/Typography';
import { DynamicContent } from '@adsk/offsite-dc-sdk';
import { MetaInfoPath } from 'mid-types';
import { PROJECT_FILES_TITLE } from '@mid-react-common/common';
import { containsSpecialCharacters, isCharacterLimitExceeded } from 'mid-utils';

const CHARACTER_LIMIT = 255;

export const validateProductDefinitionName = async (
  productName: string,
  productDefinitionId: string | undefined,
): Promise<{
  error: boolean;
  cause: string | null;
}> => {
  const allProductDefinitions = await ProductDefinitionsCrudUtils.getProductDefinitions(false);
  const productDefinitionWithTheSameName = allProductDefinitions.find((pd) => pd.name === productName);

  // If the current product definition name is longer than 255 characters
  if (productName.length > CHARACTER_LIMIT) {
    return { error: true, cause: text.productDefinitionCharacterLimit };
  }

  // if the new product definition name is empty
  if (productName === '') {
    return { error: true, cause: text.productDefinitionEmptyNamePrompt };
  }

  // if the new product definition name is not unique
  if (productDefinitionWithTheSameName && productDefinitionWithTheSameName.id !== productDefinitionId) {
    return { error: true, cause: text.productDefinitionUniqueNamePrompt };
  }

  // in other cases the name is valid
  return { error: false, cause: null };
};

export const validateProductName = (productName: string): string | null => {
  if (isCharacterLimitExceeded(productName)) {
    return text.productDefinitionCharacterLimit;
  }

  // check if product name contains special characters: /\:*?"<>|
  if (containsSpecialCharacters(productName)) {
    return text.productNameCantContainSpecialCharacters;
  }

  return null;
};

export const validateProductNameUniqueness = (
  productName: string,
  selectedFolder: MetaInfoPath,
  products?: DynamicContent[],
): { errorMessage: string; productFolderId: string; productFolderPath: string } => {
  let productFolderId: string = '';
  let productFolderPath: string = '';

  if (selectedFolder.name && selectedFolder.name !== PROJECT_FILES_TITLE && products) {
    const productWithTheSameName = products.find((product) => product.name === productName);

    if (!productWithTheSameName) {
      return {
        errorMessage: '',
        productFolderId,
        productFolderPath,
      };
    }

    productFolderPath = productWithTheSameName.context.workspace.folderPath;
    // folder path will always have some value, which means that split.at will always return a value
    productFolderId = productFolderPath.split('/').at(-1)!;
    const isSameFolderLocation = productFolderId === selectedFolder.id;

    // if we can find the product with the same name but in a different folder than the selected one, it means that the
    // user can't publish in the selected folder, because the product with the same name has already been published
    // in a different folder. User has to either change the product name or select the original folder.

    if (!isSameFolderLocation) {
      return {
        errorMessage: text.productNameUniquenessError,
        productFolderId,
        productFolderPath,
      };
    }
  }

  return {
    errorMessage: '',
    productFolderId,
    productFolderPath,
  };
};

export const getNotificationBody = (messageBody: string[]): JSX.Element => (
  <>
    {messageBody.map((message, index) => (
      <Typography key={`${message}-${index}`} variant="body2">
        {message}
      </Typography>
    ))}
  </>
);
