import { BrowserApiServiceResult, CommonBrowserApi } from '../interfaces/browserApi';
import { Result } from '../interfaces/hostApi';
import { Base64Info } from '../interfaces/fileSystem';
import { GenerateOutputsResult } from '../interfaces/inventorAutomation';
import { InventorProperties } from '../interfaces/inventorProperties';
import { Environment, FusionActiveDocumentInfo } from 'mid-types';
import { RevitClassification } from '../interfaces/revitClassification';
import { PublishChecks } from '../interfaces/publishChecks';
import { CachedOAuthTokens, FusionCommand, fusionCommands } from '../interfaces/fusion';

declare global {
  interface Window {
    adsk: {
      fusionSendData: (action: FusionCommand, data?: string) => Promise<any>;
    };
    fusionJavaScriptHandler: {
      handle: (action: string, data?: string) => string;
    };

    // Internal Fusion object. Needed to check if fusionSendData is available
    neutronJavaScriptObject: any;
  }
}

export class FusionBrowserApiService implements CommonBrowserApi {
  async loadProductDefinitions(): Promise<BrowserApiServiceResult<string>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.LOAD_PRODUCT_DEFINITIONS));
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveProductDefinitions(productDefinitions: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = JSON.parse(
      await window.adsk.fusionSendData(fusionCommands.SAVE_PRODUCT_DEFINITIONS, productDefinitions),
    );

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getActiveDocumentInfo(): Promise<BrowserApiServiceResult<FusionActiveDocumentInfo>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.GET_ACTIVE_DOCUMENT_INFO));

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getThumbnailImage(documentPath: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.GET_THUMBNAIL_IMAGE, documentPath));

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getPartOrAssemblyProperties(path: string): Promise<BrowserApiServiceResult<InventorProperties>> {
    const result: Result = JSON.parse(
      await window.adsk.fusionSendData(fusionCommands.GET_PART_OR_ASSEMBLY_PROPERTIES, path),
    );

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getEnvironment(): Promise<Environment> {
    return await window.adsk.fusionSendData(fusionCommands.GET_ENVIRONMENT);
  }

  async getModelStates(): Promise<BrowserApiServiceResult<string[]>> {
    return Promise.resolve({ value: ['[Primary]'], errorMessage: null });
  }

  async getDcApiUrl(): Promise<string> {
    return await window.adsk.fusionSendData(fusionCommands.GET_DC_API_URL);
  }

  async getMIDWebAppUrl(): Promise<string> {
    return await window.adsk.fusionSendData(fusionCommands.GET_MID_WEBAPP_URL);
  }

  async fileToBase64String(filePath: string): Promise<BrowserApiServiceResult<Base64Info>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.FILE_TO_BASE64_STRING, filePath));

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async compressFolder(folderPath: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.COMPRESS_FOLDER, folderPath));

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async generateOutputs(
    topFolderPath: string,
    documentPath: string,
    inputs: string,
    requestedOutputs: string,
  ): Promise<GenerateOutputsResult> {
    const fusionResponse = await window.adsk.fusionSendData(
      fusionCommands.GENERATE_OUTPUTS,
      JSON.stringify({
        topFolderPath,
        documentPath,
        inputs,
        requestedOutputs,
      }),
    );

    const result: GenerateOutputsResult = JSON.parse(fusionResponse).content;

    return result;
  }

  async getAssemblyVersion(): Promise<string> {
    return await window.adsk.fusionSendData(fusionCommands.GET_ASSEMBLY_VERSION);
  }

  async openExternalUrl(url: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.OPEN_EXTERNAL_URL, url));

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async deleteFile(filePath: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.DELETE_FILE, filePath));

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async openProductDefinitionDocument(documentPath: string, inputs: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.adsk.fusionSendData(
      fusionCommands.OPEN_PRODUCT_DEFINITION_DOCUMENT,
      JSON.stringify({ documentPath, inputs }),
    );
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async isDocumentOpenInTheEditor(documentPath: string): Promise<BrowserApiServiceResult<boolean>> {
    const result: Result = await window.adsk.fusionSendData(
      fusionCommands.IS_DOCUMENT_OPEN_IN_THE_EDITOR,
      JSON.stringify({ documentPath }),
    );

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async checkAndGenerateThumbnailInBase64(
    filePath: string,
    documentPath: string,
  ): Promise<BrowserApiServiceResult<Base64Info>> {
    const result: Result = JSON.parse(
      await window.adsk.fusionSendData(
        fusionCommands.CHECK_AND_GENERATE_THUMBNAIL_IN_BASE64,
        JSON.stringify({ filePath, documentPath }),
      ),
    );

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getApplicationVersionNumber(): Promise<string> {
    return await window.adsk.fusionSendData(fusionCommands.GET_APPLICATION_VERSION_NUMBER);
  }

  async saveToFile(content: string, fileName: string, fileExtension: string): Promise<BrowserApiServiceResult<string>> {
    const result: Result = await window.adsk.fusionSendData(
      fusionCommands.SAVE_TO_FILE,
      JSON.stringify({ content, fileName, fileExtension }),
    );

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getRevitClassification(): Promise<BrowserApiServiceResult<RevitClassification>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.GET_REVIT_CLASSIFICATION));

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async runPublishChecks(): Promise<BrowserApiServiceResult<PublishChecks>> {
    const result: Result = await window.adsk.fusionSendData(fusionCommands.RUN_PUBLISH_CHECKS);

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getCachedOAuthTokens(): Promise<BrowserApiServiceResult<CachedOAuthTokens>> {
    const result: Result = JSON.parse(await window.adsk.fusionSendData(fusionCommands.GET_CACHED_OAUTH_TOKENS));

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async setCachedOAuthTokens(accessToken: string, refreshToken: string): Promise<BrowserApiServiceResult<void>> {
    const result: Result = JSON.parse(
      await window.adsk.fusionSendData(
        fusionCommands.SET_CACHED_OAUTH_TOKENS,
        JSON.stringify({ accessToken, refreshToken }),
      ),
    );

    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }

    return { value: result.content, errorMessage: result.errorMessage };
  }

  async logToFile(): Promise<void> {
    // Not priority right now, it will be implemented soon
    // https://jira.autodesk.com/browse/TRADES-7033
    return;
  }

  async writeToPublishData(): Promise<void> {
    // It will be implemented soon, depends on Fusion API
    // https://jira.autodesk.com/browse/TRADES-7033
    return;
  }

  async getDrawingFiles(): Promise<string[]> {
    // Returning empty array since it is out of scope for Fusion app
    return Promise.resolve([]);
  }
}

export default new FusionBrowserApiService();
