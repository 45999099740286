import { CONTEXT_MENU_HIDDEN, CONTEXT_MENU_DISABLED, CONTEXT_MENU_ENABLED } from './constants';
import { DCInputBooleanType, DCInputNumericType, DCInputTextType } from '@adsk/offsite-dc-sdk';
import { DCInputUIExtension, DCUIInputTypes } from 'mid-types';

export const CodeblocksWorkspaceType = {
  INPUT: 'INPUT',
  FORM: 'FORM',
} as const;
export type CodeblocksWorkspaceType = (typeof CodeblocksWorkspaceType)[keyof typeof CodeblocksWorkspaceType];

export type FunctionParameter = {
  name: string;
  id: string;
  argId: string;
};

export type BaseBlock = {
  type: string;
  id: string;
  x: number;
  y: number;
  [key: string]: any;
};

export type FunctionBlock = BaseBlock & {
  fields: {
    NAME: string;
    [key: string]: any;
  };
};

export type FunctionBlockWithArgs = FunctionBlock & {
  extraState: {
    params: FunctionParameter[];
  };
};

export type BlocklyVariable = {
  name: string;
  id: string;
};

export type BlocklyWorkspace = {
  variables: BlocklyVariable[];
  blocks: {
    languageVersion: number;
    blocks: BaseBlock[];
  };
};

const blocklyFunctionTypes = ['procedures_defnoreturn', 'procedures_defreturn'];

export const isFunctionBlock = (block: BaseBlock): block is FunctionBlock => blocklyFunctionTypes.includes(block.type);
export const isFunctionBlockWithArgs = (block: BaseBlock): block is FunctionBlockWithArgs =>
  isFunctionBlock(block) && block.extraState;

export type BlocklyPluginPreconditionReturn =
  | typeof CONTEXT_MENU_DISABLED
  | typeof CONTEXT_MENU_ENABLED
  | typeof CONTEXT_MENU_HIDDEN;

export interface BlocklyInputParameterDropdownValues {
  name: string;
  type: DCUIInputTypes;
}

export type AvailableInputFunctionValues = Exclude<
  keyof DCInputUIExtension<unknown> | keyof DCInputBooleanType | keyof DCInputNumericType | keyof DCInputTextType,
  'allowCustomValue' | 'unit' | 'type' | 'onChange' | 'name'
>;
