import Typography from '@mui/material/Typography';
import text from 'inventor.text.json';
import { ProductDefinition } from 'mid-addin-lib';
import { SummaryTable, SummaryTableRow } from '@mid-react-common/common';
import React, { useEffect } from 'react';
import { ProductDefinitionSummary, PublishTableContainer } from './Publishing.styles';
import {
  getProductDefinitionSummaryData,
  getReleaseInfoSummaryData,
  getProductOutputsSummaryData,
  getRFASummaryData,
} from './publishing.utils';
import { publishTestIds } from 'tests/helpers/dataTestIds';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import OutputsSummaryTable from './OutputsSummaryTable/OutputsSummaryTable';

export interface PublishingLoadingProps {
  currentProductDefinition: Partial<ProductDefinition>;
  publishedProductName: string;
  enableStaticContent: boolean;
}

const FAKE_PUBLISH_IN_PROGRESS_DELAY = 2000;

export const PublishingLoading: React.FC<PublishingLoadingProps> = ({
  currentProductDefinition,
  publishedProductName,
  enableStaticContent,
}): JSX.Element => {
  const [currentProductDefinitionInProgress, setCurrentProductDefinitionInProgress] = React.useState<
    Partial<ProductDefinition>
  >({ name: currentProductDefinition.name, isConfigurable: currentProductDefinition.isConfigurable });

  useEffect(() => {
    const fakePublishInProgressTimeout = setTimeout(() => {
      const currentProductDefinitionKeys = Object.keys(currentProductDefinition);

      // type guard for keys of the currentProductDefinition
      const isCurrentProductDefinitionKey = (value: string): value is keyof ProductDefinition =>
        Object.keys(currentProductDefinition).includes(value);

      const randomIndex = Math.floor(Math.random() * currentProductDefinitionKeys.length);
      const currentProductDefinitionKey = currentProductDefinitionKeys[randomIndex];

      setCurrentProductDefinitionInProgress((prevState) => ({
        ...prevState,
        [currentProductDefinitionKey]:
          isCurrentProductDefinitionKey(currentProductDefinitionKey) &&
          currentProductDefinition[currentProductDefinitionKey],
      }));
    }, FAKE_PUBLISH_IN_PROGRESS_DELAY);

    return () => clearTimeout(fakePublishInProgressTimeout);
  }, [currentProductDefinitionInProgress, currentProductDefinition]);

  const releaseInfoData: SummaryTableRow[] = getReleaseInfoSummaryData({
    publishedProductName,
    currentProductDefinition: currentProductDefinitionInProgress,
    notes: currentProductDefinition.notes,
  });
  const rfaSummaryData: SummaryTableRow[] = getRFASummaryData(currentProductDefinition);
  const productDefinitionSummaryData: SummaryTableRow[] = getProductDefinitionSummaryData(
    currentProductDefinitionInProgress,
    enableStaticContent,
  );
  const productOutputsSummaryData = getProductOutputsSummaryData(currentProductDefinitionInProgress);

  return (
    <>
      <LinearProgress />
      <ProductDefinitionSummary data-testid={publishTestIds.publishReleaseSummarySection}>
        <Typography variant="h2" fontWeight="bolder" gutterBottom>
          Release
        </Typography>
        <PublishTableContainer className="mid-border-shadow">
          <SummaryTable data={releaseInfoData} removeBorder removeBackground />
        </PublishTableContainer>
      </ProductDefinitionSummary>
      <ProductDefinitionSummary data-testid={publishTestIds.productDefinitionSummarySection}>
        <Typography variant="h2" fontWeight="bolder" gutterBottom>
          {text.publishSummaryTableTitle}
        </Typography>
        <PublishTableContainer className="mid-border-shadow">
          <SummaryTable data={productDefinitionSummaryData} removeBorder removeBackground />
          <Divider />
          <SummaryTable data={rfaSummaryData} removeBorder removeBackground />
        </PublishTableContainer>
      </ProductDefinitionSummary>
      <ProductDefinitionSummary data-testid={publishTestIds.publishOutputsSummarySection}>
        <PublishTableContainer className="mid-border-shadow">
          <OutputsSummaryTable data={productOutputsSummaryData} />
        </PublishTableContainer>
      </ProductDefinitionSummary>
    </>
  );
};
